import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import {
  Section,
  Container,
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Grid,
} from 'components'

const OnboardingDefaultTitle = 'Ihr Online-Antragsformular'
const OnboardingDefaultDescription =
  ' Über einen Link erhalten Sie Ihr persönliches Antragsformular zugeschickt. Hier finden Sie ein paar Fragen zu Ihrem Finanzierungswunsch und Ihrer Person. Damit können wir Ihnen ein individuelles & konkreteres Angebot erstellen, sowie eine erste Machbarkeitszusage treffen. Schnell & einfach vergleichen wir auf diese Weise die Hypothekar-Angebote von 400 deutschen Banken für Sie.'

const CockpitDefaultTitle = 'Ihr Kundenbereich'
const CockpitDefaultDescription =
  ' Wenn Sie das Angebot in Anspruch nehmen möchten, ist Ihr Hypothekardarlehen zum Greifen nah: starten Sie den Dokumente-Upload in Ihrem Kundenbereich upload erfolgreich? Das wars! Wir reichen Ihren Antrag bei der gewählten Bank ein. in wenigen Tagen haben Sie Ihren Kreditbetrag am Konto zur Verfügung Sicher und transparent steht Ihnen auch danach Ihr Kundenbereich für Ihre Finanzübersicht zur Verfügung.'

const BambusApplicationsTeaser = ({
  onboardingTitle = OnboardingDefaultTitle,
  onboardingDescription = OnboardingDefaultDescription,
  cockpitTitle = CockpitDefaultTitle,
  cockpitDescription = CockpitDefaultDescription,
  backgroundColor = 'white',
}) => {
  const { onboardingImage, cockpitImage } = useStaticQuery(
    graphql`
      query {
        onboardingImage: file(
          relativePath: { eq: "Preview_onboarding_browser.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        cockpitImage: file(
          relativePath: { eq: "Preview_cockpit_browser.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <Section
      spacing="large"
      sx={{
        backgroundColor,
      }}
    >
      <Container>
        <Section.Body
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            as="article"
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
              gridTemplateAreas: [
                `"image"
              "text"`,
                null,
                null,
                '"text image"',
              ],
              columnGap: 11,
              rowGap: 5,
            }}
          >
            <Box sx={{ gridArea: 'text' }}>
              <Text as="h1" color="colorBlue100" fontSize={3}>
                {onboardingTitle}
              </Text>
              <Text mt={3}>{onboardingDescription}</Text>
            </Box>
            <Box
              sx={{
                gridArea: 'image',
                boxShadow: 'md',
                borderRadius: 'default',
                overflow: 'hidden',
              }}
            >
              <Image fluid={onboardingImage.childImageSharp.fluid} />
            </Box>
          </Box>
          <Box
            as="article"
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
              gridTemplateAreas: [
                `"image"
              "text"`,
                null,
                null,
                '"image text"',
              ],
              columnGap: 11,
              rowGap: 5,
              mt: [9, 12],
            }}
          >
            <Box sx={{ gridArea: 'text' }}>
              <Text as="h1" color="colorBlue100" fontSize={3}>
                {cockpitTitle}
              </Text>
              <Text mt={3}>{cockpitDescription}</Text>
            </Box>
            <Box
              sx={{
                gridArea: 'image',
                boxShadow: 'md',
                borderRadius: 'default',
                overflow: 'hidden',
              }}
            >
              <Image fluid={cockpitImage.childImageSharp.fluid} />
            </Box>
          </Box>
        </Section.Body>
      </Container>
    </Section>
  )
}

export default BambusApplicationsTeaser

import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import {
  Section,
  Container,
  Row,
  Column,
  Box,
  Heading,
  Button,
} from 'components'

import { useSharedState } from 'context/sharedState'

import AnimatedWords from './AnimatedWords'

const HeroSmall = ({
  title,
  wordsToRotate,
  subtitleFirstPart,
  subtitleLastPart,
  ctaExtraProps = {},
}) => {
  const { heroImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(relativePath: { eq: "hero-simple.png" }) {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  const [state, setState] = useSharedState()

  const onCTAPress = () => {
    setState({
      ...state,
      trackEvent: 'main_cta_complete',
    })
    window.dataLayer?.push({
      event: 'main_cta_start',
    })
    window._paq?.push(['trackEvent', 'main_cta_start', 'main_cta_start'])
  }

  /**
   * Controlled title wrapping:
   * The "title" prop can be either a simple string or an array of strings.
   * If it's an array of strings then the wrapping will be done after each array element first (can be multiple words),
   * not at the individual words' level.
   */
  const isTitleSuppliedAsLineParts = Array.isArray(title)
  const titleToShow = isTitleSuppliedAsLineParts
    ? title.map((titlePart) => (
        <span
          key={titlePart}
          style={{
            display: 'inline-block',
          }}
        >
          {titlePart}
        </span>
      ))
    : title

  return (
    <Section
      id="werteberechnung"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', null, null, 'row'],
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: ['250px', '350px', '40%'],
              mt: ['-35px'],
            }}
          >
            <Img
              fluid={heroImage.childImageSharp.fluid}
              alt="Bambus Illustration"
              loading="eager"
            />
          </Box>
          <Box
            sx={{
              zIndex: 5,
              mt: ['-40px', '0'],
              width: ['unset', null, null, '60%'],
              ml: ['0', null, null, '50px'],
            }}
          >
            <Heading.H1
              sx={{
                mt: [4, 0],
                mb: 4,
                textAlign: ['center', null, null, 'left'],
                display: ['flex', 'block'],
                flexDirection: ['column', 'unset'],
                whiteSpace: ['initial', 'pre-wrap'],
                fontSize: ['40px'],
              }}
            >
              {titleToShow}
            </Heading.H1>

            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: ['center', null, null, 'start'],
                fontSize: 3,
                mb: 6,
              }}
            >
              <Box
                sx={{
                  textAlign: ['center', null, null, 'left'],
                  width: ['100%', 'auto'],
                }}
              >
                {subtitleFirstPart}
              </Box>
              <AnimatedWords words={wordsToRotate} />
              <Box
                sx={{
                  textAlign: ['center', 'left'],
                  width: ['100%', 'auto'],
                }}
              >
                {subtitleLastPart}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ['center', null, null, 'start'],
              }}
            >
              <Button
                loading=""
                variant="main"
                id="main_cta_start"
                as="a"
                href="#anmelden"
                onClick={onCTAPress}
                sx={{
                  width: ['100%', 'auto'],
                  maxWidth: 300,
                  mx: ['auto', 0],
                }}
                {...ctaExtraProps}
              >
                Kostenlos anfragen
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Section>
  )
}

export default HeroSmall

import React from 'react'
import { useStaticQuery, graphql, Link as RouterLink } from 'gatsby'

import Layout from 'components/Layout'
import LeadCTAButton from 'components/LeadCTAButton'
import { Link, Text } from 'components'

import Hero from 'screens/Home/components/HeroSmall'
import PageSnippets from 'screens/Home/components/PageSnippets'
import FAQBase from 'screens/Home/components/FAQBase'
import Testimonials from 'screens/Home/components/Testimonials'
import Comparison from 'screens/Home/components/Comparison'
import Blog from 'screens/Home/components/Blog'
import Map from 'screens/Home/components/Map'
import Partner from 'screens/Home/components/Partner'
import BambusApplicationsTeaser from 'screens/Home/components/BambusApplicationsTeaser'
import ContactFullWidth from 'screens/Home/components/ContactFullWidth'
import Summary from 'screens/Home/components/Summary'
import Regulations from 'screens/Home/components/Regulations'
import Flow from 'screens/Home/components/Flow'

import { useLocation, useTranslate } from 'context/location'

export const teilverkaufHeaderCTAProps = {
  href: '#anmeldenTeilverkaufModal',
}

const Page = (props) => {
  const translate = useTranslate()
  const { countryCode } = useLocation()

  const {
    featuredArticles: { articles: featuredArticles },
    hypothekImage,
    contactFullWidthImage,
    triziImage,
  } = useStaticQuery(
    graphql`
      query {
        featuredArticles: contentfulFeaturedArticlesLayout(
          node_locale: { eq: "de" }
          slug: { eq: "base" }
        ) {
          articles {
            ...Article
          }
        }
        houseHypothek: file(relativePath: { eq: "haus-hypothek.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        hypothekImage: file(relativePath: { eq: "haus-hypothek.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        teilverkaufImage: file(relativePath: { eq: "gold-sparschwein.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        losungImage: file(relativePath: { eq: "losung.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        houseCoinsImage: file(relativePath: { eq: "house-coins-sprouts.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        renovationImage: file(relativePath: { eq: "renovieren.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        womanDogImage: file(relativePath: { eq: "old-woman-dog.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        contactFullWidthImage: file(
          relativePath: { eq: "sales-photos/sales_team-10.jpeg" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        salesImage: file(
          relativePath: { eq: "sales-photos/sales_team-9.jpeg" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        triziImage: file(relativePath: { eq: "team-13-mirrored.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        bambusHouseImage: file(relativePath: { eq: "bambus-haus.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <Layout
      seo={{
        title: 'Die passende Finanzierung für Ihren Wohntraum.',
        description:
          'Wir finden die passende Lösung für Sie und Ihr Haus oder Ihre Wohnung.',
      }}
    >
      {/* Soft hyphenation is used to prevent forced breaking */}
      <Hero
        title={['Die passende Finanzierung ', 'für Ihren ', 'Wohntraum.']}
        wordsToRotate={['bauen', 'kaufen', 'finanzieren']}
        subtitleFirstPart="Sie möchten eine neue Immobilie"
        subtitleLastPart={''}
      />
      <Flow
        title="Ihre Finanzierung in vier Schritten"
        items={[
          {
            title: 'Budgeterfassung',
            description:
              'Mit der ersten Bestandsaufnahme definieren wir Ihr Budget für den Immobilienkauf',
          },
          {
            title: 'Angebot und Beratung',
            description:
              'Mit Ihrer personalisierten Antragstrecke erhalten Sie innerhalb von 24 Stunden ein Angebot, zu dem wir Sie umfänglich beraten',
          },
          {
            title: 'Prüfung',
            description:
              'Über den Bambus Kundenbereich werden nur die nötigen Dokumente schnell und einfach angefragt und von unseren Experten geprüft',
          },
          {
            title: 'Auszahlung',
            description:
              'Wird Ihre Finanzierung zugesagt, erhalten Sie den Kreditvertrag und können Ihren Wohntraum erfüllen',
          },
        ]}
        ctaElement={
          <LeadCTAButton
            variant="primary"
            width={['100%', 'auto']}
            bg="blue"
            leadModalType="reduced"
            ctaButtonID="Finanzieren--Flow"
            signupReason="information"
          >
            Kostenlos anfragen
          </LeadCTAButton>
        }
      />
      <PageSnippets
        snippets={[
          {
            id: 1,
            title: 'Unser Service',
            description:
              'Gemeinsam finden wir Ihre optimale Finanzierungslösung – unser Expertenteam unterstützt Sie dabei.',
            link: '/beratung',
            gatsbyFluidImage: triziImage.childImageSharp.fluid,
          },
        ]}
      />
      <Comparison
        title="Die bessere Wahl"
        items={[
          {
            title: 'Girobank',
            details: [
              'Begrenzte Auswahl',
              'Keine Überblick',
              'Analoge Prozesse',
              'Nur lokal',
            ],
          },
          {
            primary: true,
            title: null,
            details: [
              'Eine Anfrage - viele Banken',
              'Kostenfreie Beratung',
              'Einfach Online',
              'Übersichtliche Prozesse',
            ],
          },
          {
            title: 'Vergleichsportale',
            details: [
              'Realisierbarkeit ungewiss',
              'Keine persönliche Beratung',
              'Stangenware',
              'Hohe Gebühren',
            ],
          },
        ]}
        ctaButtonID="Finanzieren--Comparison"
      />
      <ContactFullWidth
        title="Unverbindlich anfragen"
        subheading="Wir freuen uns über Ihre Anfrage"
        email="beratung@bambus.io"
        leadModalTypeBlueButton="standard_callback"
        leadModalTypePinkButton="standard_offer"
        phoneNumber={translate({
          DE: '+49 30 837 960 40',
          AT: '+43 800 00 7171',
          _: '+43 800 00 7171',
        })}
        gatsbyFluidImage={contactFullWidthImage.childImageSharp.fluid}
        imagePosition={['50% 0px', '100px 0px', '150px 30%']}
        ctaBlueButtonID="Finanzieren--ContactFullWidth-Blue"
        ctaPinkButtonID="Finanzieren--ContactFullWidth-Pink"
      />
      <BambusApplicationsTeaser
        onboardingDescription={translate({
          DE: 'Über einen Link erhalten Sie Ihr persönliches Antragsformular zugeschickt. Hier finden Sie ein paar Fragen zu Ihrem Finanzierungswunsch und Ihrer Person. Damit können wir Ihnen ein individuelles & konkreteres Angebot erstellen, sowie eine erste Machbarkeitszusage treffen. Schnell & einfach vergleichen wir auf diese Weise die Hypothekar-Angebote von 400 deutschen Banken für Sie.',
          AT: 'Über einen Link erhalten Sie Ihr persönliches Antragsformular zugeschickt. Hier finden Sie ein paar Fragen zu Ihrem Finanzierungswunsch und Ihrer Person. Damit können wir Ihnen ein individuelles & konkreteres Angebot erstellen, sowie eine erste Machbarkeitszusage treffen. Schnell & einfach vergleichen wir auf diese Weise die Hypothekar-Angebote von mehreren heimischen Banken für Sie.',
          _: 'Über einen Link erhalten Sie Ihr persönliches Antragsformular zugeschickt. Hier finden Sie ein paar Fragen zu Ihrem Finanzierungswunsch und Ihrer Person. Damit können wir Ihnen ein individuelles & konkreteres Angebot erstellen, sowie eine erste Machbarkeitszusage treffen. Schnell & einfach vergleichen wir auf diese Weise die Hypothekar-Angebote von 400 deutschen Banken für Sie.',
        })}
        cockpitDescription="Wenn Sie das Angebot in Anspruch nehmen möchten, ist Ihr Hypothekardarlehen zum Greifen nah. Dafür starten Sie einfach den Dokumenten-Upload in Ihrem Kundenbereich. Wenn der Upload erfolgreich war, sind Sie schon fertig. Wir reichen Ihren Antrag bei der gewählten Bank ein. In wenigen Tagen haben Sie Ihren Kreditbetrag am Konto zur Verfügung. Sicher und transparent steht Ihnen auch danach Ihr Kundenbereich für Ihre Finanzübersicht zur Verfügung."
      />
      <Testimonials
        showCTA
        ctaButtonID="Finanzieren--Testimonials"
        leadModalType="standard_offer"
      />
      <Summary
        title="Unsere Werte"
        items={[
          {
            icon: '/icons/bambus-clarity.svg',
            iconWidth: '77px',
            title: 'Übersichtlich',
            description:
              'Wir sorgen dafür, dass Sie stets den Überblick behalten.',
          },
          {
            icon: '/icons/bambus-transparency.svg',
            iconWidth: '79px',
            title: 'Transparent',
            description:
              'Jeder Schritt ist für Sie nachvollziehbar und verständlich – ohne Abschluss keine Bezahlung.',
          },
          {
            icon: '/icons/bambus-sustainability.svg',
            iconWidth: '82px',
            title: 'Nachhaltig',
            description:
              'Unsere Beratung konzentriert sich auf nachhaltige Finanzierung.',
          },
        ]}
        ctaButtonID="Finanzieren--Summary"
        leadModalType="standard_offer"
      />
      <FAQBase
        title="Sie wollen mehr Informationen?"
        subheading="Hier finden Sie einige FAQs & Definitionen – einfach erklärt!"
      />
      <Blog
        title="Tauchen Sie in unseren Ratgeber ein"
        articles={featuredArticles}
        subheadingElement={
          <Text textAlign="center" color="colorBlue90">
            Diese und weitere Artikel finden Sie in unserem{' '}
            <Link as={RouterLink} to="/ratgeber">
              Ratgeber
            </Link>
          </Text>
        }
      />
      <Map />
      <Partner />
      <Regulations country={countryCode} />
    </Layout>
  )
}
export default Page
